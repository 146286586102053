import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import bannerPic from '../../assets/images/coding.jpg';
import Header from '../../components/Header';
import clj from '../../assets/images/clj.png';
import cljs from '../../assets/images/cljs.png';
import js from '../../assets/images/js.png';
import library from '../../assets/images/library.jpeg';
import octocat from '../../assets/images/octocat.png';
import csharp from '../../assets/images/csharp.png';

const cljTag = <img className="tag" src={clj} />;
const cljsTag = <img className="tag" src={cljs} />;
const jsTag = <img className="tag" src={js} />;
const csharpTag = <img className="tag" src={csharp} />;


const OpenSource = (props) => (
    <Layout>
        <Header></Header>
        <div id="open-source">
            <Helmet>
                <title>Dave Martin Programming - Open Source</title>
                <meta name="description" content="Dave Martin Programming Open Source" />
            </Helmet>

            <section id="banner" style={{ backgroundImage: `url(${bannerPic})` }}>
                <div className="inner">
                    <header className="major">
                        <h1>My Open Source Work</h1>
                    </header>
                    <div className="content">
                        <p>
                            I maintain or contribute to a number of open source libraries
                        </p>
                        <ul className="actions">
                            <li><a target="_blank" href="https://github.com/DaveWM" className="button next">See my GitHub</a></li>
                        </ul>
                    </div>
                </div>
            </section>

            <div id="main">
                <section id="two" className="spotlights">
                    <section>
                        <img src={library} className="image" alt="" />
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>My Libraries</h3>
                                </header>
                                <div className="content">
                                    <p>Libraries that I have created, and currently maintain.</p>
                                    <ul>
                                        <li><a target="_blank" href="https://github.com/DaveWM/willa">Willa</a>{cljTag}- an idiomatic Clojure DSL for Kafka Streams</li>
                                        <li><a target="_blank" href="https://github.com/DaveWM/reagent-material-ui">Reagent Material UI</a>{cljTag}-  a Reagent wrapper for Material UI</li>
                                        <li><a target="_blank" href="https://github.com/DaveWM/larch">Larch</a>{cljsTag}-  an implementation of the Elm architecture in idiomatic ClojureScript</li>
                                        <li><a target="_blank" href="https://github.com/DaveWM/ngwizard">ngWizard</a>{jsTag}- a wizard component for AngularJS</li>
                                        <li><a target="_blank" href="https://github.com/DaveWM/attyc">ATTyC</a>{cljTag}- type checker for AngularJS templates, using TypeScript</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <img src={octocat} className="image with-background" alt="" />
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>Projects I've Contributed to</h3>
                                </header>
                                <div className="content">
                                    <ul>
                                    <li><a target="_blank" href="https://github.com/FundingCircle/jackdaw">Jackdaw</a>{cljTag}- a Clojure library for Apache Kafka</li>
                                    <li><a target="_blank" href="https://github.com/octokit/octokit.net">OctoKit</a>{csharpTag}- a GitHub API client library for C#</li>
                                    <li><a target="_blank" href="http://angular-ui.github.io/bootstrap/">UI Bootstrap</a>{jsTag}- Bootstrap components for AngularJS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </div>
    </Layout>
)

export default OpenSource;